import { client } from '~/utils/client';
import { notifications } from '../slices/notifications-slice';
import { AppDispatch } from '../store';

export const fetchNotifications = () => async (dispatch: AppDispatch) => {
  const response = await client
    .get('/player/notifications')
    .then(res => res.data);
  dispatch(notifications.actions.init(response));
};

export const markNotificationAsSeen =
  (notificationId: string) => async (dispatch: AppDispatch) => {
    const response = await client
      .put(`/player/notifications/${notificationId}`)
      .then(res => res.data);
    dispatch(notifications.actions.update(response));
  };

import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react';
import { Button } from '~/components/0_atoms/Button/Button';
import { Input } from '~/components/0_atoms/Input/Input';
import { FormGroup } from '~/components/0_atoms/FormGroup/FormGroup';
import { CoatOfArms } from '~/components/0_atoms/CoatOfArms/CoatOfArms';
import { client } from '~/utils/client';
import store from '~/store/store';
import { fetchPlayerData } from '~/store/thunks/player-thunks';
import { randomHexColor } from '~/utils/helpers';

type FormState = {
  name: string;
  dynasty: string;
  motto: string;
  shape: 'circle' | 'square';
  primaryColor: string;
  secondaryColor: string;
};

export const CreatePlayerPage = () => {
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<FormState>({
    name: '',
    dynasty: '',
    motto: '',
    shape: 'circle',
    // random hex color
    primaryColor: randomHexColor(),
    secondaryColor: randomHexColor(),
  });

  const handleFormChange = useCallback(
    (key: keyof FormState) => (e: ChangeEvent<HTMLInputElement>) =>
      setForm(p => ({ ...p, [key]: e.target.value })),
    [],
  );

  const isFormValid = useMemo(
    () => Object.values(form).every(value => !!value && value.length > 3),
    [form],
  );

  const handleLogin = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);

      client
        .post<{ player: any }>('/player', form)
        .then(() => {
          store.dispatch(fetchPlayerData());
          // updatePlayerInfo(data.player);
        })
        .catch(error => {
          setLoading(false);
          console.error(error);
          alert(error.message);
        });
    },
    [form],
  );

  return (
    <main className="w-full h-full flex flex-col items-center justify-center p-8">
      <div className="max-w-xs w-full bg-gray-400 rounded p-4">
        <form onSubmit={handleLogin} method="post">
          <h1 className="text-center mb-3">Create your Character</h1>
          <FormGroup label="Name" id="name">
            <Input value={form.name} onChange={handleFormChange('name')} />
          </FormGroup>

          <FormGroup label="Dynasty Name" id="dynasty">
            <Input
              value={form.dynasty}
              onChange={handleFormChange('dynasty')}
            />
          </FormGroup>
          <FormGroup label="Dynasty Motto" id="motto">
            <Input value={form.motto} onChange={handleFormChange('motto')} />
          </FormGroup>
          <div className="flex flex-row justify-between items-center gap-3">
            <label>
              Coat of Arms
              <br />
              Colors
            </label>
            <div className="flex flex-col gap-1">
              <Input
                value={form.primaryColor}
                type="color"
                onChange={handleFormChange('primaryColor')}
                className="h-6 w-6 p-0 bg-transparent"
              />
              <Input
                value={form.secondaryColor}
                type="color"
                onChange={handleFormChange('secondaryColor')}
                className="h-6 w-6 p-0 bg-transparent"
              />
            </div>
            <div className="p-4">
              <CoatOfArms {...form} className="h-24" />
            </div>
          </div>

          <Button disabled={loading || !isFormValid} type="submit" primary>
            Start
          </Button>
        </form>
      </div>
    </main>
  );
};

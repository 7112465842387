import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { War } from '~/types/orm/models';

type WarState = {
  list: War[];
  selected: War | null;
};

export const war = createSlice({
  name: 'war',
  initialState: {
    list: [],
    selected: null,
  } as WarState,
  reducers: {
    init: (state, action: PayloadAction<War[]>) => {
      state.list = action.payload;
    },
    select: (state, action: PayloadAction<War>) => {
      state.selected = action.payload;
    },
    update: (state, action: PayloadAction<War>) => {
      const index = state.list.findIndex(w => w.id === action.payload.id);
      if (index === -1) {
        state.list.push(action.payload);
      } else {
        state.list[index] = action.payload;
      }
      if (state.selected && state.selected.id === action.payload.id) {
        state.selected = action.payload;
      }
    },
    remove: (state, action: PayloadAction<War>) => {
      state.list = state.list.filter(w => w.id !== action.payload.id);
      if (state.selected && state.selected.id === action.payload.id) {
        state.selected = null;
      }
    },
    deselect: state => {
      state.selected = null;
    },
  },
});

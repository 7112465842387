import Bowser from 'bowser';
import dayjs, { Dayjs } from 'dayjs';
import { War } from '~/types/orm/models';

export const notNullish = <T>(value: T | null | undefined): value is T =>
  value != null;

export const getProgressBetweenDates = (
  start: Dayjs,
  end: Dayjs,
  now: Dayjs,
) => {
  const total = end.diff(start, 'days');
  const current = now.diff(start, 'days');

  return Math.min((current / total) * 100, 100);
};

export const cAbs = (value: number) =>
  value < 0 ? Math.abs(value) : value * -1;

export const formatDate = (date: Date | string | Dayjs) =>
  dayjs(date).format('MMM DD, YYYY [AD]');

export const random = (seed: number) => {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
};

export type CancellablePromise = {
  promise: Promise<unknown>;
  cancel: () => void;
};

export const cancellablePromise = <T>(
  promise: Promise<T>,
): CancellablePromise => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      value => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      error => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  };
};

export const delay = (n: number) =>
  new Promise(resolve => setTimeout(resolve, n));

export const randomHexColor = () => {
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 16).toString(16);
  }
  return color;
};

export const PLATFORM = Bowser.parse(window.navigator.userAgent);
export const isMobile =
  PLATFORM.platform.type === 'mobile' || PLATFORM.platform.type === 'tablet';

export const getWarScore = (war: War) => {
  const { battleScore, siegeScore, bonusScore } = war;
  return Math.max(battleScore + siegeScore + bonusScore);
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '~/types/orm/models';

type NotificationsState = {
  items: Notification[];
};

export const notifications = createSlice({
  name: 'notifications',
  initialState: {
    items: [],
  } as NotificationsState,
  reducers: {
    init: (state, action: PayloadAction<Notification[]>) => {
      state.items = action.payload;
    },
    update: (state, action: PayloadAction<Notification>) => {
      const index = state.items.findIndex(n => n.id === action.payload.id);
      if (index === -1) {
        state.items.push(action.payload);
      } else {
        state.items[index] = action.payload;
      }
    },
  },
});

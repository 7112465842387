export type Player = {
  id: string;
  characterId: string;
  uid: string;
  character: Character;
};

export type Character = {
  id: string;
  name: string;
  sex: 'MALE' | 'FEMALE';
  gold: number;
  isNpc: boolean;
  dynastyId: string;
  titleId: string;
  dynasty: Dynasty;
  title: Title;
  titles: Title[];
};

export type Dynasty = {
  id: string;
  name: string;
  motto: string;
  headId: string;
  coaId: string;
  head: Character;
  coa: Coa;
};

export enum TitleRank {
  BARONY = 'BARONY',
  COUNTY = 'COUNTY',
  DUCHY = 'DUCHY',
  KINGDOM = 'KINGDOM',
  EMPIRE = 'EMPIRE',
}

export type Title = {
  id: string;
  name: string;
  shortName: string;
  rank: TitleRank;
  holderId: string;
  liegeId: string;
  holdingId: string;
  coaId: string;
  holder: Character;
  liege: Title;
  holding: Holding;
  coa: Coa;
  vassals: Title[];
};

export type Coa = {
  id: string;
  shape: string;
  primaryColor: string;
  secondaryColor: string;
};

export enum HoldingType {
  NONE = 'NONE',
  TRIBE = 'TRIBE',
  CITY = 'CITY',
  TEMPLE = 'TEMPLE',
  CASTLE = 'CASTLE',
}

export type Holding = {
  id: string;
  name: string;
  shortName: string;
  type: HoldingType;
  level: number;
  title: Title;
  occupiedBy: Character | null;
  occupiedById: string | null;
  buildings: Building[];
  sieges: Siege[];
  x: number;
  y: number;
  goldIncome: number;
  levies: number;
  maxLevies: number;
  garrison: number;
  maxGarrison: number;
  buildGameTime: Date | null;
  buildGameStartedAt: Date | null;
  county: County;
  countyId: string;
};

export type County = {
  id: string;
  name: string;
  shortName: string;
  capital: Holding;
  capitalId: string;
  baronies: Holding[];
};

export type Building = {
  id: string;
  type: string;
  level: number;
  buildGameTime: Date | null;
  buildGameStartedAt: Date | null;
};

export enum ArmyType {
  RAID_PARTY = 'RAID_PARTY',
  WAR_PARTY = 'WAR_PARTY',
}

export enum ArmyState {
  GATHERING = 'GATHERING',
  ACTIVE = 'ACTIVE',
  BATTLE = 'BATTLE',
  SIEGE = 'SIEGE',
  RETREATING = 'RETREATING',
  DISBANDING = 'DISBANDING',
  DISBANDED = 'DISBANDED',
}

export type Army = {
  id: string;
  name: string;
  commander: Character;
  commanderId: string;
  ruler: Character;
  rulerId: string;
  x: number;
  y: number;
  targetX: number;
  targetY: number;
  levies: number;
  type: ArmyType;
  state: ArmyState;
  stateLockedUntil: Date | null;
  disbandedAt: Date | null;
  raisedAt: Date;
  movedAt: Date;
};

export enum BattlePhase {
  PREPARATION = 'PREPARATION',
  BATTLE = 'BATTLE',
  RETREAT = 'RETREAT',
  END = 'END',
}

export type Battle = {
  id: string;
  name: string;
  x: number;
  y: number;
  phase: BattlePhase;
  phaseStartedAt: Date;
  attacker: Army;
  attackerId: string;
  defender: Army;
  defenderId: string;
  startedAt: Date;
  endedAt: Date | null;
};

export enum SiegePhase {
  PREPARATION = 'PREPARATION',
  SIEGE = 'SIEGE',
  ASSAULT = 'ASSAULT',
  END = 'END',
}

export type Siege = {
  id: string;
  name: string;
  x: number;
  y: number;
  phase: SiegePhase;
  phaseStartedAt: Date;
  progress: number;
  maxProgress: number;
  attacker: Army;
  attackerId: string;
  holding: Holding;
  holdingId: string;
  startedAt: Date;
  endedAt: Date | null;
};

export type War = {
  id: string;
  attackerId: string;
  defenderId: string;
  battleScore: number;
  siegeScore: number;
  bonusScore: number;
  attacker: Character;
  defender: Character;
  startedAt: Date;
  endedAt: Date | null;
};

export type Notification = {
  id: string;
  receiver: Player;
  receiverId: string;
  type: string;
  payload: any;
  gameDate: Date;
  seenAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Character } from '~/types/orm/models';

type DrawerState = {
  character: Character;
  history: Character[];
};

export const drawer = createSlice({
  name: 'drawer',
  initialState: {
    character: undefined,
    history: [],
  } as unknown as DrawerState,
  reducers: {
    push: (state, action: PayloadAction<Character>) => {
      state.character = action.payload;
      state.history.push(action.payload);
    },
    pop: state => {
      const last = state.history.pop();
      if (last) {
        state.character = last;
      } else {
        state.character = undefined!;
        state.history = [];
      }
    },
    reset: state => {
      state.character = undefined!;
      state.history = [];
    },
  },
});

import './wdyr';
import React from 'react';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { register } from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './index.css';

import { AuthorizationMiddleware } from './components/8_middlewares/AuthorizationMiddleware/AuthorizationMiddleware';
import store from './store/store';
import { fetchWorldData } from './store/thunks/world-thunks';

dayjs.extend(relativeTime);

store.dispatch(fetchWorldData());

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthorizationMiddleware />
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
register({
  onUpdate(registration) {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Update available. Do you want to update now?')) {
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

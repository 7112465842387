import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { BuildingData, HoldingData } from '~/types/world/models';

type WorldState = {
  loading: boolean;
  seed: string;
  startingDate: string;
  date: string;
  changedAt: string;
  dayDuration: number;
  raisedLeviesCost: number;
  holdings: Record<string, HoldingData>;
  buildings: BuildingData[];
};

type WorldConfig = {
  seed: string;
  dayDuration: number;
  worldStartingDate: string;
  worldDate: string;
  dayChangedAt: number;
  nextDayChangesAt: number;
  raisedLeviesCost: number;
  holdings: Record<string, HoldingData>;
  buildings: Record<string, BuildingData>;
};

export const world = createSlice({
  name: 'world',
  initialState: {
    loading: true,
    dayDuration: 60,
    seed: 'seed',
    startingDate: dayjs('0375-01-01').toISOString(),
    date: dayjs('0375-01-01').toISOString(),
    changedAt: dayjs().toISOString(),
    raisedLeviesCost: 0,
    holdings: {},
    buildings: {},
  } as WorldState,
  reducers: {
    start: state => {
      state.loading = true;
    },
    init: (state, action: PayloadAction<WorldConfig>) => {
      state.loading = false;
      state.dayDuration = action.payload.dayDuration;
      state.startingDate = action.payload.worldStartingDate;
      state.date = action.payload.worldDate;
      state.raisedLeviesCost = action.payload.raisedLeviesCost;
      state.changedAt = dayjs(action.payload.dayChangedAt).toISOString();
      state.holdings = action.payload.holdings;
      state.buildings = Object.entries(action.payload.buildings).reduce(
        (p, c) => [
          ...p,
          {
            ...c[1],
            id: c[0],
          },
        ],
        [] as BuildingData[],
      );
    },
    updateDate: (
      state,
      action: PayloadAction<{ date: string; changedAt: string }>,
    ) => {
      state.loading = false;
      state.date = action.payload.date;
      state.changedAt = action.payload.changedAt;
    },
  },
});

import {
  ButtonHTMLAttributes,
  forwardRef,
  MouseEvent,
  PropsWithChildren,
  useCallback,
} from 'react';
import useSound from 'use-sound';
import popDownSfx from '~/assets/sounds/pop-down.mp3';
import popUpOnSfx from '~/assets/sounds/pop-up-on.mp3';
import popUpOffSfx from '~/assets/sounds/pop-up-off.mp3';
import styles from './Button.module.css';
import clsx from 'clsx';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  primary?: boolean;
  secondary?: boolean;
};

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      onMouseDown,
      onMouseUp,
      onMouseEnter,
      onMouseLeave,
      className,
      primary,
      secondary,
      ...props
    },
    ref,
  ) => {
    const [playActive] = useSound(popDownSfx);
    const [playOn] = useSound(popUpOnSfx);
    const [playOff] = useSound(popUpOffSfx);

    const handleMouseDown = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        playActive();
        onMouseDown?.(event);
      },
      [onMouseDown, playActive],
    );

    const handleMouseUp = useCallback(() => {
      if (props.type === 'submit') {
        playOn();
      } else {
        playOff();
      }
    }, [playOff, playOn, props.type]);

    return (
      <button
        ref={ref}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        className={clsx(
          styles.button,
          {
            [styles.primary]: primary,
            [styles.secondary]: secondary,
          },
          className,
        )}
        {...props}
      />
    );
  },
);

import { AppDispatch } from '../store';
import { fetchBattles } from './battles-thunks';
import { fetchMapData } from './map-thunks';
import { fetchNotifications } from './notifications-thunks';
import { fetchPlayerData } from './player-thunks';
import { fetchSieges } from './sieges-thunks';
import { fetchWars } from './war-thunks';

export const fetchInitialPlayerData = () => async (dispatch: AppDispatch) => {
  dispatch(fetchPlayerData());
  dispatch(fetchNotifications());
};

export const fetchInitialWorldData = () => async (dispatch: AppDispatch) => {
  dispatch(fetchMapData());
  dispatch(fetchWars());
  dispatch(fetchBattles());
  dispatch(fetchSieges());
};

import { configureStore } from '@reduxjs/toolkit';
import { battles } from './slices/battles-slice';
import { drawer } from './slices/drawer-slice';
import { map } from './slices/map-slice';
import { notifications } from './slices/notifications-slice';
import { player } from './slices/player-slice';
import { sieges } from './slices/sieges-slice';
import { war } from './slices/war-slice';
import { world } from './slices/world-slice';

const store = configureStore({
  reducer: {
    world: world.reducer,
    player: player.reducer,
    map: map.reducer,
    drawer: drawer.reducer,
    war: war.reducer,
    notifications: notifications.reducer,
    battles: battles.reducer,
    sieges: sieges.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;

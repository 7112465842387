import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vector2d } from 'konva/lib/types';
import { Army, ArmyState, Holding } from '~/types/orm/models';

export enum FooterAction {
  NONE,
  TILE,
  HOLDING,
  ARMY,
  BATTLE,
  SIEGE,
}

type SetSelection = {
  action: FooterAction;
  selection?: string;
};

type MapState = {
  focus: Vector2d;
  position: Vector2d | null;
  action: FooterAction;
  selection: string | undefined;
  loading: boolean;
  ready: boolean;
  error: string | null;
  holdings: Holding[];
  armies: Army[];
};

export const map = createSlice({
  name: 'map',
  initialState: {
    focus: { x: -1, y: -1 },
    loading: true,
    ready: false,
    error: null,
    holdings: [],
    armies: [],
    position: null,
    action: FooterAction.NONE,
    selection: undefined,
  } as MapState,
  reducers: {
    start: state => {
      state.loading = true;
    },
    update: (
      state,
      action: PayloadAction<{ holdings: Holding[]; armies: Army[] }>,
    ) => {
      state.holdings = action.payload.holdings;
      state.armies = action.payload.armies.filter(
        item => item.state !== ArmyState.DISBANDED,
      );
      state.loading = false;
      state.ready = true;
      state.error = null;
    },
    updateHolding: (state, action: PayloadAction<Holding>) => {
      const index = state.holdings.findIndex(a => a.id === action.payload.id);
      if (index === -1) {
        state.holdings.push(action.payload);
      } else {
        state.holdings[index] = action.payload;
      }
    },
    setArmies: (state, action: PayloadAction<Army[]>) => {
      state.armies = action.payload.filter(
        item => item.state !== ArmyState.DISBANDED,
      );
    },
    updateArmy: (state, action: PayloadAction<Army>) => {
      const index = state.armies.findIndex(a => a.id === action.payload.id);
      if (index === -1) {
        state.armies.push(action.payload);
      } else {
        state.armies[index] = action.payload;
      }
      state.armies = state.armies.filter(
        item => item.state !== ArmyState.DISBANDED,
      );
    },
    disbandArmy: (state, action: PayloadAction<Army>) => {
      const index = state.armies.findIndex(a => a.id === action.payload.id);
      if (index !== -1) {
        state.armies.splice(index, 1);
      }
      state.armies = state.armies.filter(
        item => item.state !== ArmyState.DISBANDED,
      );
    },
    setFocus: (state, action: PayloadAction<Vector2d>) => {
      state.focus = action.payload;
    },
    setPosition: (state, action: PayloadAction<Vector2d | null>) => {
      state.position = action.payload;
    },
    setSelection: (state, action: PayloadAction<SetSelection>) => {
      state.action = action.payload.action;
      state.selection = action.payload.selection;
      if (action.payload.action !== FooterAction.TILE) {
        state.position = null;
      }
    },
    closeSelection: state => {
      state.action = FooterAction.NONE;
      state.selection = undefined;
      state.position = null;
    },
  },
});

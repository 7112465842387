import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { FooterAction } from '~/store/slices/map-slice';
import { AppDispatch, RootState } from '~/store/store';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const usePlayerCharacter = () =>
  useAppSelector(state => state.player?.player?.character!);

export const useSelectCoordinates = () =>
  useAppSelector(state => state.map.position!);

export const useSelectBaroniesAt = (x: number, y: number) =>
  useAppSelector(state =>
    state.map.holdings.filter(b => b.x === x && b.y === y),
  );

export const useSelectArmiesAt = (x: number, y: number) =>
  useAppSelector(state => state.map.armies.filter(b => b.x === x && b.y === y));

export const useSelectBattlesAt = (x: number, y: number) =>
  useAppSelector(state =>
    state.battles.items.filter(b => b.x === x && b.y === y),
  );

export const useSelectSiegesAt = (x: number, y: number) =>
  useAppSelector(state =>
    state.sieges.items.filter(b => b.x === x && b.y === y),
  );

export const useSelectArmy = (id: string) =>
  useAppSelector(state => state.map.armies.find(a => a.id === id)!);

export const useSelectedArmy = () =>
  useAppSelector(state => {
    if (state.map.action === FooterAction.ARMY) {
      return state.map.armies.find(a => a.id === state.map.selection);
    }
    return undefined;
  });

export const useSelectedSiege = () =>
  useAppSelector(state => {
    if (state.map.action === FooterAction.SIEGE) {
      return state.sieges.items.find(a => a.id === state.map.selection);
    }
    return undefined;
  });

export const useSelectedBattle = () =>
  useAppSelector(state => {
    if (state.map.action === FooterAction.BATTLE) {
      return state.battles.items.find(a => a.id === state.map.selection);
    }
    return undefined;
  });

import { Army } from '~/types/orm/models';
import { client } from '~/utils/client';
import { FooterAction, map } from '../slices/map-slice';
import { AppDispatch } from '../store';

export const fetchMapData = () => async (dispatch: AppDispatch) => {
  dispatch(map.actions.start());
  const response = await client.get('/map').then(res => res.data);
  dispatch(map.actions.update(response));
};

export const selectArmy =
  (army: Army, focus: boolean = false) =>
  async (dispatch: AppDispatch) => {
    dispatch(
      map.actions.setSelection({
        action: FooterAction.ARMY,
        selection: army.id,
      }),
    );
    // dispatch(map.actions.setPosition({ x: army.x, y: army.y }));
    if (focus) {
      dispatch(map.actions.setFocus({ x: army.x, y: army.y }));
    }
  };

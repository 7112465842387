import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Character, Player } from '~/types/orm/models';

type PlayerState = {
  loading: boolean;
  ready: boolean;
  error: string | null;
  player: Player;
  accessToken?: string;
};

export const player = createSlice({
  name: 'player',
  initialState: {
    loading: true,
    ready: false,
  } as PlayerState,
  reducers: {
    start: state => {
      state.loading = true;
    },
    update: (state, action: PayloadAction<Player>) => {
      state.player = action.payload;
      state.loading = false;
      state.ready = true;
      state.error = null;
    },
    updateCharacter: (state, action: PayloadAction<Character>) => {
      state.player.character = action.payload;
    },
    updateGold(state, action: PayloadAction<number>) {
      state.player.character.gold = action.payload;
    },
    updateAccessToken(state, action: PayloadAction<string | undefined>) {
      state.accessToken = action.payload;
    },
  },
});

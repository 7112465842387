import axios from 'axios';
import { auth } from './firebase';

let _idToken: string | null = null;

export const getIdTokenStatic = () => _idToken;

export const getIdToken = async () => {
  _idToken = await auth.currentUser?.getIdToken()!;
  return _idToken;
};

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

client.interceptors.request.use(
  async config => {
    const token = await getIdToken();
    (config as any).headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error),
);

client.interceptors.response.use(
  success => success,
  async error => {
    if (error.response.status === 401) {
      await auth.signOut();
      alert('Your session has expired. Please log in again.');
    } else if (
      String(error.response.status).startsWith('4') &&
      error.response.status !== 404
    ) {
      alert(error.response.data.errorMessage);
    }

    return Promise.reject(error);
  },
);

import { client } from '~/utils/client';
import { player } from '../slices/player-slice';
import { AppDispatch } from '../store';

// export const fetchPlayerData = createAsyncThunk(
//   'player/upsate',
//   // Declare the type your function argument here:
//   async () => {
//     return await client.get('/player').then(res => res.data);
//   },
// );

export const fetchPlayerData = () => async (dispatch: AppDispatch) => {
  const response = await client.get('/player').then(res => res.data);
  dispatch(player.actions.update(response));
};

import { memo, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import loadable from '@loadable/component';

import { auth } from '~/utils/firebase';
import { SignInMiddleware } from '../RouterMiddlewares/SignInMiddleware';
import { CreatePlayerPage } from '~/components/9_pages/CreatePlayerPage/CreatePlayerPage';
import store from '~/store/store';
import { useAppSelector } from '~/hooks/useAppState';
import { Player } from '~/types/orm/models';
import { player } from '~/store/slices/player-slice';
import { SplashScreen } from '~/components/1_molecules/SplashScreen/SplashScreen';
import {
  fetchInitialPlayerData,
  fetchInitialWorldData,
} from '~/store/thunks/init-thunks';

const GameRouter = loadable(() =>
  import('../RouterMiddlewares/GameRouterMiddleware').then(
    module => module.GameRouterMiddleware,
  ),
);

export const AuthorizationMiddleware = memo(() => {
  const token = useAppSelector(state => state.player.accessToken);
  const playerState = useAppSelector(state => state.player.player);
  const loading = useAppSelector(state => state.player.loading);
  const ready = useAppSelector(state => state.player.ready);

  useEffect(() => {
    store.dispatch(player.actions.start());
    onAuthStateChanged(auth, user => {
      if (user) {
        auth.currentUser?.getIdToken().then(idToken => {
          console.log('user info should load?');
          store.dispatch(player.actions.updateAccessToken(idToken));
          store.dispatch(fetchInitialPlayerData());
          store.dispatch(fetchInitialWorldData());
        });
      } else {
        store.dispatch(player.actions.updateAccessToken(undefined));
        store.dispatch(player.actions.update(null as unknown as Player));
      }
    });
  }, []);

  if (loading) {
    return <SplashScreen />;
  }

  if (!token) {
    return <SignInMiddleware />;
  }

  if (!ready) {
    return <SplashScreen />;
  }

  return !!playerState?.characterId && !!token ? (
    <GameRouter />
  ) : (
    <CreatePlayerPage />
  );
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Siege } from '~/types/orm/models';

type SiegesState = {
  items: Siege[];
  selected: Siege | null;
};

export const sieges = createSlice({
  name: 'sieges',
  initialState: {
    items: [],
    selected: null,
  } as SiegesState,
  reducers: {
    init: (state, action: PayloadAction<Siege[]>) => {
      state.items = action.payload;
    },
    select: (state, action: PayloadAction<Siege>) => {
      state.selected = action.payload;
    },
    update: (state, action: PayloadAction<Siege>) => {
      const index = state.items.findIndex(w => w.id === action.payload.id);
      if (index === -1) {
        state.items.push(action.payload);
      } else {
        state.items[index] = action.payload;
      }
      if (state.selected && state.selected.id === action.payload.id) {
        state.selected = action.payload;
      }
    },
    remove: (state, action: PayloadAction<Siege>) => {
      state.items = state.items.filter(w => w.id !== action.payload.id);
      if (state.selected && state.selected.id === action.payload.id) {
        state.selected = null;
      }
    },
    deselect: state => {
      state.selected = null;
    },
  },
});
